<template>
  <div>
    <MyHeader :title="$t('lang.menu_mydept')" :ShowBack="true" />
    <van-cell-group
      v-for="mCompany in lstCompany"
      :key="mCompany.id"
      :title="mCompany.name"
    >
      <van-cell
        v-for="mData in getDeptList(mCompany.id)"
        :key="mData.id"
        :title="mData.name"
        :value="mData.r"
      />
    </van-cell-group>
  </div>
</template>
<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
  data() {
    return {
      lstCompany: this.$store.state.companys,
      lstDepartment: this.$store.state.depts,
    };
  },
  methods: {
    getDeptList(companyid) {
      return this.lstDepartment.filter((d) => {
        return d.companyId == companyid;
      });
    },
  },
};
</script>
